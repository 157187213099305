import React from "react";
import { Container, Carousel, Image, Jumbotron } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

function About(props) {
  return (
    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
      <Container>
        <Image
          className="d-block w-20 img-fluid rounded mx-auto d-block"
          src="images/threadGallery/logos/400dpiLogoCropped.png"
          alt="Natural Beauty"
          height="300"
          width="500"
        />
      </Container>
   {/* <p className="shadow-lg text-danger bg-warning text-justify browz-text text-center text-uppercase animated heartBeat browz-notice-animated">
        *** We are closed on Tuesday, January 02, 2024. Sorry for inconvenience. Call or text on 857-770-8825 for more information.
        Thank You. ***
      </p> */}
      <Container style={{ backgroundImage: "url('images/bg.jpg')" }} fluid>
        <Carousel>
          <Carousel.Item>
            <Image
              className="d-block w-20 mx-auto img-fluid"
              src="images/work-6.jpg"
              alt="Pretty Eybrow"
            />
            <Carousel.Caption>
              <h3 className="bg-dark">Get Pretty Eyebrow</h3>
              <p
                className="lead bg-info font-weight-bold shadow-lg p-3 rounded"
                style={{ color: "#000000" }}
              >
                Lets put an icing on your natural god gift beauty by taking care
                of you eyebrow and other unwanted facial hair.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="d-block w-20 mx-auto img-fluid"
              src="images/work-7.jpg"
              alt="Natural Beauty"
            />

            <Carousel.Caption>
              <h3
                className="bg-dark"
                style={{ fontFamily: "Pacific0, cursive" }}
              >
                Natural Beauty
              </h3>
              <p
                className="lead bg-info font-weight-bold shadow-lg p-3 rounded"
                style={{ color: "#000000" }}
              >
                The Browz Threading is a full service threading salon dedicated
                to ensuring that our clients look and feel their best
                eyebrows. Choose from a range of threading services and makeup
                for special occasions or as part of a commitment to a regular
                beauty regimen. Taking care of your eyebrows, face skin is not a
                luxury in today’s fast-paced world, but essential to good health
                and your general wellbeing.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
      <Jumbotron fluid>
        <Container className="text-center" fluid>
          <h2 className="bg-dark text-center" style={{ color: "white" }}>
            Well-known truly commuinity appreciated Threading Salon
          </h2>
          <p
            className="lead bg-info font-weight-bold shadow-lg p-3 rounded"
            style={{ color: "#000000" }}
          >
            With years of well experienced Threading Specialist, we are
            committed to serve our customers. We
            guarantee you a beautifully shaped eyebrow.
          </p>
        </Container>
      </Jumbotron>
    </ScrollAnimation>
  );
}

export default About;
