import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Appointment from "./components/Appointment";
import Hours from "./components/Hours";
import Galleria from "./components/Galleria";
import Contact from "./components/Contact";
import { Container } from "reactstrap";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./App.css";

function App() {
  return (
    <Router>
      <Container
        fluid
        style={{ backgroundImage: "url('images/bg.jpg')" }}
        data-stellar-background-ratio="0.5"
      >
        <NavBar />
      </Container>

      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/services" exact component={Services} />
      <Route path="/appointment" exact component={Appointment} />
      <Route path="/hours" exact component={Hours} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/gallery" exact component={Galleria} />
    </Router>
  );
}

export default App;
