import React from "react";
import About from "./About";
import Services from "./Services";
import Hours from "./Hours";
import Galleria from "./Galleria";
import Contact from "./Contact";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import { Container } from "react-bootstrap";
import Appointment from "./Appointment";

function Home(props) {
  return (
    <Container fluid style={{ backgroundImage: "url('images/bg.jpg')" }}>
      <About />
      <Services />
{/*       <Appointment /> */}
      <Hours />
      <Galleria />
      <Contact />
      <Testimonials />
      <Footer />
    </Container>
  );
}

export default Home;
