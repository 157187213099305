import React from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";

function Testimonials(props) {
  return (
    <Container>
      <Row>
        <Col className="col-sm-4 col-md-4 col-lg-4">
          <Image
            className="d-block w-20 img-fluid"
            src="images/testimony-isha.jpg"
            alt="Pretty Eybrow"
          />
        </Col>
        <Col className="col-sm-8 col-md-8 col-lg-8">
          <h3>
            <strong>Testimonial</strong>
          </h3>
          <Container className="seprator"></Container>
          <Container
            id="carousel-example-generic"
            className="carousel slide"
            data-ride="carousel"
            fluid
          >
            <Carousel>
              <Carousel.Item>
                <span
                  className="yelp-review"
                  data-review-id="66lP7klVkfM3HjsSKvn3RA"
                  data-hostname="www.yelp.com"
                >
                  Read{" "}
                  <a
                    href="https://www.yelp.com/user_details?userid=BLv2oaEt-lsagQWpc656cw"
                    rel="nofollow noopener"
                  >
                    Shamu W.
                  </a>
                  's{" "}
                  <a
                    href="https://www.yelp.com/biz/the-browz-threading-cambridge?hrid=66lP7klVkfM3HjsSKvn3RA"
                    rel="nofollow noopener"
                  >
                    review{" "}
                  </a>
                  of{" "}
                  <a
                    href="https://www.yelp.com/biz/pvp2wsq9eb0RI-rmOKSBoQ"
                    rel="nofollow noopener"
                  >
                    The Browz Threading{" "}
                  </a>
                  on{" "}
                  <a href="https://www.yelp.com" rel="nofollow noopener">
                    Yelp
                  </a>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span
                  className="yelp-review"
                  data-review-id="BemIPgc9FXbqogtfQ0gycw"
                  data-hostname="www.yelp.com"
                >
                  Read{" "}
                  <a
                    href="https://www.yelp.com/user_details?userid=hVkkWefqj7MErZbmlYvBRw"
                    rel="nofollow noopener"
                  >
                    Aasawari N.
                  </a>
                  's{" "}
                  <a
                    href="https://www.yelp.com/biz/the-browz-threading-cambridge?hrid=BemIPgc9FXbqogtfQ0gycw"
                    rel="nofollow noopener"
                  >
                    review{" "}
                  </a>
                  of{" "}
                  <a
                    href="https://www.yelp.com/biz/pvp2wsq9eb0RI-rmOKSBoQ"
                    rel="nofollow noopener"
                  >
                    The Browz Threading{" "}
                  </a>
                  on{" "}
                  <a href="https://www.yelp.com" rel="nofollow noopener">
                    Yelp
                  </a>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span
                  className="yelp-review"
                  data-review-id="fZPMeFTmc76YpgR89_NgqQ"
                  data-hostname="www.yelp.com"
                >
                  Read{" "}
                  <a
                    href="https://www.yelp.com/user_details?userid=LAYRlbnqnsAAB6GuN1c03w"
                    rel="nofollow noopener"
                  >
                    Kassy B.
                  </a>
                  's{" "}
                  <a
                    href="https://www.yelp.com/biz/the-browz-threading-cambridge?hrid=fZPMeFTmc76YpgR89_NgqQ"
                    rel="nofollow noopener"
                  >
                    review{" "}
                  </a>
                  of{" "}
                  <a
                    href="https://www.yelp.com/biz/pvp2wsq9eb0RI-rmOKSBoQ"
                    rel="nofollow noopener"
                  >
                    The Browz Threading{" "}
                  </a>
                  on{" "}
                  <a href="https://www.yelp.com" rel="nofollow noopener">
                    Yelp
                  </a>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span
                  className="yelp-review"
                  data-review-id="4xebgMfhkQK6LvOJrXUpBA"
                  data-hostname="www.yelp.com"
                >
                  Read{" "}
                  <a
                    href="https://www.yelp.com/user_details?userid=iBQdjmRtjUHZsvizUjXAcw"
                    rel="nofollow noopener"
                  >
                    Jennifer P.
                  </a>
                  's{" "}
                  <a
                    href="https://www.yelp.com/biz/the-browz-threading-cambridge?hrid=4xebgMfhkQK6LvOJrXUpBA"
                    rel="nofollow noopener"
                  >
                    review{" "}
                  </a>{" "}
                  of
                  <a
                    href="https://www.yelp.com/biz/pvp2wsq9eb0RI-rmOKSBoQ"
                    rel="nofollow noopener"
                  >
                    The Browz Threading{" "}
                  </a>
                  on{" "}
                  <a href="https://www.yelp.com" rel="nofollow noopener">
                    Yelp
                  </a>
                </span>
              </Carousel.Item>
            </Carousel>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Testimonials;
