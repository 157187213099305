import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

class Appointment extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <section className="my-5">
          <Container fluid>
         
            <Row>
              <Col md={4} className="embed-responsive embed-responsive-1by1"> 
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLScyd91dZ4WORaDOejhsQzu44dNGKxJbjyLDVL6rjuhSVqTNfw/viewform?embedded=true" 
                width="800" 
                height="600px" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0">
                    Loading…
                </iframe>
              </Col>

             
            </Row>
          </Container>
        </section>
      </ScrollAnimation>
    );
  }
}

export default Appointment;
