export const photos = [
  {
    src: "images/threadGallery/eyebrow-1.png",
    width: 3,
    height: 4
  },
  {
    src: "images/threadGallery/eyebrow-2.png",
    width: 2,
    height: 3
  },
  {
    src: "images/threadGallery/eyebrow-3.png",
    width: 3,
    height: 4
  },
  {
    src: "images/threadGallery/eyebrow-9.jpg",
    width: 3,
    height: 3
  },
  {
    src: "images/threadGallery/eyebrow-10.jpg",
    width: 4,
    height: 3
  },
  {
    src: "images/threadGallery/eyebrow-11.jpg",
    width: 4,
    height: 3
  },
  {
    src: "images/threadGallery/eyebrow-12.jpg",
    width: 5,
    height: 2
  },
  {
    src: "images/threadGallery/eyebrow-13.jpg",
    width: 4,
    height: 2
  },
  {
    src: "images/threadGallery/eyebrows-both.jpg",
    width: 5,
    height: 2
  },
  {
    src: "images/threadGallery/eyebrow-threading.jpg",
    width: 4,
    height: 3
  },
  {
    src: "images/threadGallery/indoor-sofa.jpg",
    width: 5,
    height: 3
  },

  {
    src: "images/threadGallery/makeup-chair-wide.jpg",
    width: 6,
    height: 3
  },
  {
    src: "images/threadGallery/salon-chair-2.jpg",
    width: 7,
    height: 3
  },
  {
    src: "images/threadGallery/browz-bg-1.jpg",
    width: 5,
    height: 3
  },
  {
    src: "images/threadGallery/browz-salon-chairs.jpg",
    width: 7,
    height: 4
  }
];
