import React, { Component } from "react";
import { Container } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

class Hours extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <Container className="business-hours mt-10" fluid>
          <h2 className="title">Opening Hours</h2>
          <ul className="list-unstyled opening-hours">
            <li>
              Sunday <span className="pull-right">11:00 AM - 5:00 PM</span>
            </li>
            <li>
              Monday <span className="pull-right">11:00 PM - 7:00 PM</span>
            </li>
            <li>
              Tuesday <span className="pull-right">11:00 AM - 7:00 PM</span>
            </li>
            <li>
              Wednesday <span className="pull-right">11:00 AM - 7:00 PM</span>
            </li>
            <li>
              Thursday <span className="pull-right">11:00 AM - 7:00 PM</span>
            </li>
            <li>
              Friday <span className="pull-right">11:00 AM - 7:00 PM</span>
            </li>
            <li>
              Saturday <span className="pull-right">10:00 AM - 6:00 PM</span>
            </li>
          </ul>
        </Container>
      </ScrollAnimation>
    );
  }
}
export default Hours;
