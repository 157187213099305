import React from "react";

function Footer(props) {
  return (
    <div>
      <footer className="page-footer font-small special-color-dark pt-4">
        <div className="text-center">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a
                className="btn-floating btn-sm btn-fb mx-1"
                href="https://www.facebook.com/thebrowzthreading"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-facebook fa-2x"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-sm btn-tw mx-1"
                href="https://twitter.com/browzthreading"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-twitter fa-2x"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-sm btn-gplus mx-1"
                href="https://www.instagram.com/thebrowzthreading"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-instagram fa-2x"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-sm btn-li mx-1"
                href="https://www.pinterest.com/thebrowzthreading/pins"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-pinterest fa-2x"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-sm btn-dribbble mx-1"
                href="https://www.yelp.com/biz/the-browz-threadig-enhance-your-natural-beauty-cambridge"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa fa-yelp fa-2x"></i>
              </a>
            </li>
          </ul>
        </div>

        <div
          className="footer-copyright py-3 text-center"
          style={{ color: "rgb(128, 15, 15)", fontWeight: "bold" }}
        >
          © {new Date().getFullYear()} Copyright: The Browz Threading - All Rights Reserved.
          <br></br>
          Developed by: Dinesh Thapa
        </div>
      </footer>
    </div>
  );
}

export default Footer;
